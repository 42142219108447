html,body,#root {
    min-height: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
}

.MuiCircularProgress-root {
    margin-left: calc(50% - 20px) !important;
    margin-top: calc(50% - 20px) !important;
}

.MuiAppBar-root {
    background-color: #026ed5;

    .MuiToolbar-root {
        background-color: #026ed5;

        .browser .MuiGrid-item:first-child .MuiTypography-root {
            position: relative;
            top: 9px;
        }

        .mobile .MuiGrid-item:first-child .MuiTypography-root {
            position: relative;
            top: 3px;
        }

        .MuiGrid-item:last-child {
            padding-top: 0px;
            text-align: right;

            .login {
                margin-top: 8px;
                color: white;
                text-decoration: underline;
            }
        }
    }
}

.MuiDrawer-root {
    .MuiButton-containedSecondary {
        background-color: #f23c41;
    }

    .drawer-header {
        min-height: 48px;
        background-color: #e2e2e2;
    }

    .MuiList-root {
        padding-top: 0;
    }

    .nav-header { 
        background-color: whitesmoke;
        .MuiListItemText-primary {
            font-weight: bold !important;
        }
    }
}

.logging_in {
    padding-top: 100px;
    text-align: center;
}

.page {
    height: 100%;
    padding-top:47px;
    background: rgb(245, 245, 245);

    >div {
        height: 100%;
    }

    .helpIcon {
        position: absolute !important;
        top: 0px;
        right: 60px;
        z-index: 9999999;
    }

    .browserRoot {
        height: 100%;
        max-width: none;
        
        overflow: auto;
        margin:0;
        padding:0;

        >* {
            margin:auto;
        }
    }

    .mobileRoot {
        height: 100%;
        max-width: none;
        overflow: auto;
        margin:0;
        padding:0;
    }

    .news-outer {
        padding: 10px; 
        padding-top: 20px;

        .news {
            margin: 10px 10% 10px calc(15% - 0px);
            padding-bottom: 10px;
            background: #ebebeb;
            border-radius: 4px 20px 20px;
            position: relative;
            box-shadow: none;
            border: none;
    
            .news-headshot {
                position: absolute;
                left: -70px;
                top: 0px;
                width: 60px;
                border-radius: 3px;
            } 
    
            .news-author {
                position: absolute;
                left: 20px;
                top: 15px;
                font-weight: bold;
                margin-bottom: 20px;
            }
    
            .news-date {
                font-weight: bold;
                position: absolute;
                right: 20px;
                top: 15px;
                white-space: nowrap;
            }

            .news-message {
                margin: 25px 0 0 5px;
            }

            .disqus-comment-count {
                position: absolute;
                right: 50px;
                bottom: 4px;
                text-align: right;
                color: gray;
            }

            .MuiAccordionSummary-expandIcon {
                position: absolute;
                right: 20px;
                bottom: -10px;
            }

            iframe {
                border: 0;
            }
        }

        .MuiAccordion-root:before {
            background: none;
        }
    }

    .my-players {

        .select-team,.select-player {
            min-width: 250px;
        }
        #select-team-placeholder {
            margin-left: 30px;
            margin-right: 10px;
        }
        #select-player-placeholder {
            margin-left: 50px;
            margin-right: 10px;
        }
    }

    .dashboard {
        width: 100%;
        height: 1400px;
        overflow: hidden;
        margin: auto;
        position: relative; 
        padding-top: 10px;

        .tour_step {
            position: absolute;
        }

        iframe {
            overflow: hidden;
        }
    }

    .list-header {
        background-color: #efefef;
    }
}

.reactour__close {
    top: 9px;
    right: 9px;
}